import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { getWarehouseAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import wareHouseLogo from "../../assets/images/warehouse-logo.png"
import { FaArrowLeft, FaCheck, FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import BuyWarehouse from "../modals/BuyWarehouse";
export default function WarehouseDetailPage(){
    const {id} = useParams();
    const dispatch =  useDispatch();
    useEffect(()=>{
        if(id){
            dispatch(getWarehouseAsyncThunk(id))
        }
    },[id,dispatch])
    const user = useSelector((s)=>s.auth?.user)
    useEffect(()=>{
        
    })
    // console.log("user",user);
    

    const warehouse = useSelector((state) => state.warehouse.warehouse);
    
    // console.log("ware house images",warehouse?.images);
    const [modalShow,setModalShow] = useState(false)
    const handleModalShow = ()=> setModalShow(true)
    const handleModalHide = ()=> setModalShow(false)
    
    return(
        <div className="warehouse-page-wrapper">
            <div className="container-fluid">

            <div className="page-title">
                <div className="back-btn">
                    <Link to="/warehouse-listing"><FaArrowLeft size={15}/>  Back</Link>
                </div>
                <h1>{warehouse?.name}</h1>
                {user?.role === "seller" ? (<div>
                    <button onClick={handleModalShow} className={`${warehouse?.isActive && warehouse?.capacity?.remaining ? "active" : "inactive"} buy-button`}>{warehouse?.isActive && warehouse?.capacity?.remaining ? "Buy Warehouse" : "Inactive"}</button>
                    <BuyWarehouse modalShow={modalShow} handleModalHide={handleModalHide} warehouse={warehouse}/>
                </div>):(<div></div>)}
                
            </div>
            <div className="page-header-section">
                <div className="warehouse-image">
                    {warehouse?.images?.length > 0 ? (<img src={`http://localhost:8000/public/uploads/images/${warehouse?.images[0]}` || `https://garagapp.geniusmindzone.online/public/uploads/images/${warehouse?.images[0]}`}/>):(<img src={wareHouseLogo} alt="image" />)}
                    
                </div>
                <div className="warehouse-basic-info">
                    <p>{warehouse?.location?.street},{warehouse?.location?.city},<br />{warehouse?.location?.state},{warehouse?.location?.country},<br />{warehouse?.location?.zipCode}</p>
                    <span className={warehouse?.isActive && warehouse?.capacity?.remaining ? "active" : "inactive"}>{warehouse?.isActive && warehouse?.capacity?.remaining ? "Active" : "Inactive"}</span>
                </div>
                <div className="storage-info">
                    <div className="single-info">
                        <strong>Size :</strong>
                        <span>{warehouse?.size} square feets</span>
                    </div>
                    <div className="single-info">
                        <strong>Height :</strong>
                        <span>{warehouse?.height} meters</span>
                    </div>
                    <div className="single-info">
                        <strong>Total Capacity :</strong>
                        <span>{warehouse?.capacity?.total} units</span>
                    </div>
                    <div className="single-info">
                        <strong>Capacity Remaining :</strong>
                        <span>{warehouse?.capacity?.remaining || 0}  units</span>
                    </div>
                </div>
                <div className="pricing-details">
                    <div className="single-info">
                        <strong>Fulfillment fee :</strong>
                        <span>${warehouse?.pricing?.fulfillmentFee}</span>
                    </div>
                    <div className="single-info">
                        <strong>Storage per unit :</strong>
                        <span>${warehouse?.pricing?.storagePerUnit}</span>
                    </div>
                </div>
            </div>
            <div className="services-list">
                <h2>Services Provided</h2>
                <div className="services-wrapper">
                <div className="single-service">
                    <div className="check">
                        {warehouse?.services?.pickAndPack ? <FaCheckCircle color="green"/> : <MdCancel color="red"/>}
                    </div>
                    <strong>Pick and Pack</strong>
                </div>
                <div className="single-service">
                    <div className="check">
                        {warehouse?.services?.labeling ? <FaCheckCircle color="green"/> : <MdCancel color="red"/>}
                    </div>
                    <strong>Labeling</strong>
                </div>
                <div className="single-service">
                    <div className="check">
                        {warehouse?.services?.expeditedShipping ? <FaCheckCircle color="green"/> : <MdCancel color="red"/>}
                    </div>
                    <strong>Expeditted Shipping</strong>
                </div>
                </div>
                
            </div>
            <div className="categories-list">
                <h2>Categories Covered</h2>
                <ul>
                    {warehouse?.categories?.map((category,index)=>(
                        <li key={index}><FaCheckCircle color="green"/> {category}</li>
                    ))}
                </ul>
            </div>
            <div className="description">
                <p>{warehouse?.description}</p>
            </div>
            <div className="images-gallery">
                <h2>Images Gallery</h2>
                <div className="row">
                    {warehouse?.images?.map((image,index)=>(
                        <div className="single-image col-md-4">
                        <img src={`http://localhost:8000/public/uploads/images/${image}` || `https://garagapp.geniusmindzone.online/public/uploads/images/${image}`} alt="" />
                </div>
                    ))}
                
                </div>
                
            </div>
            </div>
            
        </div>
    )
}