import React from 'react';

// Props: message (optional) and image (optional)
const NoDataFound = ({ message = "No data found", image,description }) => {
  return (
    <div className="no-data-found flex flex-col items-center justify-center h-full w-full text-center p-4">
      {image && (
        <img
          src={image}
          alt="No Data"
        />
      )}
      <h2 className="text-xl font-semibold text-gray-600">{message}</h2>
      <p className="text-gray-500 mt-2">
        {description}
      </p>
    </div>
  );
};

export default NoDataFound;
