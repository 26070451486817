import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createOrderAsyncThunk } from "../../redux/pagesSlices/orderSlice";
import { updateWarehouseAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import { categories } from "../../utils/categoriesList";

export default function BuyWarehouse({modalShow,handleModalHide,warehouse}) {
    console.log("Total capacity",warehouse?.capacity?.total);
    
    const validationSchema = Yup.object().shape({
        orderDetails : Yup.object().shape({
            productName : Yup.string().required("Product name is required"),
            quantity: Yup.number()
            .required("Quantity is required")
            .min(1, "Quantity must be at least 1")
            .test(
                "max-remaining-capacity",
                "Quantity cannot exceed the remaining capacity",
                function (value) {
                    const total = warehouse?.capacity?.total || 0;
                    const used = warehouse?.capacity?.remaining || 0;
                    return value <= used;
                  }
            ),
            isStackable : Yup.string().required("Please select if it's stackable or not"),
            isFragile : Yup.string().required("Please select if it's fragile or not"),
            customerName : Yup.string().required("Your name is required"),
            customerAddress : Yup.string().required("Your address is required"),
            productType : Yup.string().required("Product type is required"),
        }),
        boxDimensions : Yup.object().shape({
            height : Yup.number().required("Height is required").equals([25], "Box height must be 25"),
            width : Yup.number().required("Width is required").equals([18], "Box width must be 18"),
        }),
        packagingDetails : Yup.object().shape({
            pickAndPack : Yup.string().required("Please select if it's pick and pack or not"),
            labeling : Yup.string().required("Please select if it's labeled or not"),
        }),
        totalCost : Yup.number(),
        shippingDetails : Yup.object().shape({
            carrier : Yup.string().required("Carrier name is required"),
            trackingNumber : Yup.string().required("Tracking number is required"),
        })
    })

    const dispatch = useDispatch();
    const handleBuyWarehouse = (values) => {
        console.log("values",values);
        
        const quantity = Number(values?.orderDetails?.quantity) || 0; // Ensure quantity is a number
        const usedCapacity = Number(warehouse?.capacity?.remaining) || 0; // Ensure used capacity is a number
        
        const updatedCapacity = usedCapacity - quantity; // Perform the calculation
        const isWarehouseFull = usedCapacity <= 0;
        console.log("is warehouse full",isWarehouseFull);
        console.log("used capacity",usedCapacity);
        console.log("quantity",quantity);
        
        
        console.log("updated capacity",updatedCapacity);
        
        dispatch(createOrderAsyncThunk({
            data : values
        }))
        dispatch(updateWarehouseAsyncThunk({
            id : warehouse?.id,
            data : {
                capacity : {
                    total : warehouse?.capacity?.total,
                    remaining : updatedCapacity
                },
                isActive : isWarehouseFull ? "false" : "true"
            }
        }))
        handleModalHide();
        
    }
    return(
        <Modal className="modal-wrapper" centered show={modalShow} onHide={handleModalHide} size="lg">
            <Modal.Header closeButton>
                <strong>
                Buy Warehouse
                </strong>
                
            </Modal.Header>
            <Modal.Body>
                    <Formik
                        initialValues={{
                            // name: warehouse?.name || "",
                            // capacity : {
                            //     total: warehouse?.capacity?.total || 0,
                            //     used : warehouse?.capacity?.used || 0
                            // },
                            warehouseId : warehouse?.id,
                            warehouseDetails : {
                                warehouseName : warehouse?.name || "",
                                // warehouseId : warehouse?.id
                            },
                            orderDetails : {
                                productName : "",
                                quantity : 0,
                                isStackable : "",
                                isFragile : "",
                                customerName : "",
                                customerAddress : "",
                                productType : ""
                            },
                            boxDimensions : {
                                height : 0,
                                width : 0,
                            },
                            packagingDetails : {
                                pickAndPack : "",
                                labeling : "",
                            },
                            totalCost : 500,
                            shippingDetails : {
                                carrier : "",
                                trackingNumber : "",
                            }
                            
                        }}
                        onSubmit={handleBuyWarehouse}
                        validationSchema={validationSchema}
                    >
                        {({values,handleChange,handleBlur,handleSubmit,errors,touched})=>(
                            <div className="buy-warehouse-form">
                                <div className="form-section">
                                    <strong>Warehouse details</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Warehouse Name</label>
                                        <input type="text" value={values?.warehouseDetails?.warehouseName} disabled />
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Remaining Capacity (Units)</label>
                                        <input type="number" value={warehouse?.capacity?.remaining} disabled />
                                    </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-section">
                                    <strong>Order details</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Product Name</label>
                                        <input type="text" name="orderDetails.productName" value={values?.orderDetails?.productName} onChange={handleChange} onBlur={handleBlur} />
                                        {errors?.orderDetails?.productName && touched?.orderDetails?.productName && <span className="error">{errors?.orderDetails?.productName}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Product Type</label>
                                        <select name="orderDetails.productType" value={values?.orderDetails?.productType} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select Product Type</option>
                                            {categories.map((category,index) => (
                                                <option value={category} key={index}>{category}</option>
                                            ))}
                                        </select>
                                        {errors?.orderDetails?.productType && touched?.orderDetails?.productType && <span className="error">{errors?.orderDetails?.productType}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Quantity</label>
                                        <input type="number" name="orderDetails.quantity" value={values?.orderDetails?.quantity} onChange={handleChange} onBlur={handleBlur} />
                                        {errors?.orderDetails?.quantity && touched?.orderDetails?.quantity && <span className="error">{errors?.orderDetails?.quantity}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Is Stackable?</label>
                                        <select name="orderDetails.isStackable" value={values?.orderDetails?.isStackable} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        {errors?.orderDetails?.isStackable && touched?.orderDetails?.isStackable && <span className="error">{errors?.orderDetails?.isStackable}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Is Fragile?</label>
                                        <select name="orderDetails.isFragile" value={values?.orderDetails?.isFragile} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        {errors?.orderDetails?.isFragile && touched?.orderDetails?.isFragile && <span className="error">{errors?.orderDetails?.isFragile}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Your Name</label>
                                        <input type="text" value={values?.orderDetails?.customerName} onChange={handleChange} onBlur={handleBlur} name="orderDetails.customerName" />
                                        {errors?.orderDetails?.customerName && touched?.orderDetails?.customerName && <span className="error">{errors?.orderDetails?.customerName}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Your Address</label>
                                        <input type="text" value={values?.orderDetails?.customerAddress} onChange={handleChange} onBlur={handleBlur} name="orderDetails.customerAddress" />
                                        {errors?.orderDetails?.customerAddress && touched?.orderDetails?.customerAddress && <span className="error">{errors?.orderDetails?.customerAddress}</span>}
                                    </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-section">
                                    <strong>Packaging details</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Packing?</label>
                                        <select name="packagingDetails.pickAndPack" value={values?.packagingDetails?.pickAndPack} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        {errors?.packagingDetails?.pickAndPack && touched?.packagingDetails?.pickAndPack && <span className="error">{errors?.packagingDetails?.pickAndPack}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Labeling?</label>
                                        <select name="packagingDetails.labeling" value={values?.packagingDetails?.labeling}  onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        {errors?.packagingDetails?.labeling && touched?.packagingDetails?.labeling && <span className="error">{errors?.packagingDetails?.labeling}</span>}
                                    </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="form-section">
                                    <strong>Box dimensions (in inches)</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Enter height of box</label>
                                        <input type="number" name="boxDimensions.height" value={values?.boxDimensions?.height} onChange={handleChange} onBlur={handleBlur} id=""/>
                                        {errors?.boxDimensions?.height && touched?.boxDimensions?.height && <span className="error">{errors?.boxDimensions?.height}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Enter width of box</label>
                                        <input type="number" name="boxDimensions.width" value={values?.boxDimensions?.width} onChange={handleChange} onBlur={handleBlur} id=""/>
                                        {errors?.boxDimensions?.width && touched?.boxDimensions?.width && <span className="error">{errors?.boxDimensions?.width}</span>}
                                    </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="form-section">
                                    <strong>Shipping details</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Carrier Name</label>
                                        <input type="text" value={values?.shippingDetails?.carrier} name="shippingDetails.carrier" onChange={handleChange} onBlur={handleBlur} />
                                        {errors?.shippingDetails?.carrier && touched?.shippingDetails?.carrier && <span className="error">{errors?.shippingDetails?.carrier}</span>}
                                    </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        <label htmlFor="">Tracking Number</label>
                                        <input type="text" name="shippingDetails.trackingNumber" value={values?.shippingDetails?.trackingNumber} onChange={handleChange} onBlur={handleBlur}  id=""/>
                                        {errors?.shippingDetails?.trackingNumber && touched?.shippingDetails?.trackingNumber && <span className="error">{errors?.shippingDetails?.trackingNumber}</span>}
                                    </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="form-section">
                                    <strong>Total Cost</strong>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="single-field">
                                        
                                        <input type="number" name="totalCost" value={values?.totalCost} disabled />
                                    </div>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                </div>
                                <button className="submit-button" type="submit" onClick={handleSubmit}>
                                    Place Order
                                </button>
                            </div>
                        )}
                    </Formik>
            </Modal.Body>
        </Modal>
    )
}