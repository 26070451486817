import { FaEye, FaTrash } from "react-icons/fa";
import PageHeader from "../PageHeader";

export default function PaymentsPage(){
    return(
        <div className="page-wrapper">
            <div className="page-title">
                <h1>Recent Payments</h1>
            </div>
            <PageHeader />
            <div className="warehouse-listing-table">
            <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Amount</th>
                            <th>Customer Name</th>
                            <th>Customer Email</th>
                            <th>Paymemt Date</th>
                            <th>Status</th>
                            {/* <th>Order Total</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>$5000</td>
                            <td>John Doe</td>
                            <td>johndoe@example.com</td>
                            <td>2022-01-01</td>
                            <td><span  className="completed">Completed</span></td>
                            {/* <td>$100.00</td> */}
                            <td className="action-buttons"><button>
                                <FaEye />
                                </button>
                                <button>
                                    <FaTrash />
                                </button>
                                </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}