import { FaSearch } from "react-icons/fa"
import { categories } from "../utils/categoriesList"

export default function OrdersPageHeader({date,setDate,category,setCategory,trackingNumber,setTrackingNumber,status,setStatus,totalCost,setTotalCost,search,setSearch}) {
    

    return(
        <div className="page-header">
            <div className="filter-dropdown">
                <select name="" id="" value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Filter by Category</option>
                    {categories.map((category) => (
                        <option value={category}>{category}</option>
                    ))}
                </select>
                <input type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} placeholder="Filter by Tracking Number"/>
                <input type="number" value={totalCost} onChange={(e) => setTotalCost(e.target.value)} placeholder="Filter by total cost"/>
                {/* <select name="" id="">
                    <option value="">Filter</option>
                    {filterLabels.map((label,index) => (
                        <option value={index}>{label}</option>
                    ))}
                </select> */}
                <select name="" id="" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Filter by status</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delievered</option>
                    <option value="Returned">Returned</option>
                </select>
                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} name="" id="" />
            </div>
            <div className="search-bar">
                <FaSearch />
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..."/>
            </div>
        </div>
    )
}