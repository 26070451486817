export const categories = [
    "Electronics",
    "Clothing",
    "Furniture",
    "Toys",
    "Books",
    "Sports",
    "Health",
    "Beauty",
    "Home",
    "Garden",
    "Jewelry",
    "Shoes",
    "Accessories",
    "Music",
    "Movies",
    "Games",   
]