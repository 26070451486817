import { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getOrderAsyncThunk, updateOrderAsyncThunk } from "../../redux/pagesSlices/orderSlice";
import { toast } from "react-toastify";

export default function OrderDetailPage(){
    const {id} = useParams();
    console.log("id",id);
    
    const dispatch = useDispatch();
    useEffect(()=>{
        if(id){
            dispatch(getOrderAsyncThunk(id))
        }
    },[id,dispatch])
    const order = useSelector((state) => state.order?.order);
    console.log("order",order);

    const handleUpdateOrderStatus = async(id) => {
        dispatch(updateOrderAsyncThunk({id, data: {shippingDetails:{ ...order?.shippingDetails, status: "Approved"}},callback : () => {
            toast.success("Order Approved successfully")
            setTimeout(()=>{
                dispatch(getOrderAsyncThunk(id))

            },3000)
        }}))
    }
    const user = useSelector((state)=>state?.auth?.user)

    
    return(
        <div className="order-detail-page-wrapper">
            <div className="container-fluid">
            <div className="page-title-section">
    <Link to={"/orders"} className="back-link">
        <FaArrowLeft /> Back
    </Link>

    <h1 className="title">Order Details</h1>

    {order?.shippingDetails?.status === "Pending" && user?.role === "brand" ? (
        <button className="approve-btn" onClick={() => handleUpdateOrderStatus(order?.id)}>
            Approve Order
        </button>
    ) : (
        <div className="empty-space"></div>
    )}
</div>

<div class="section">
  <h2>Warehouse Details</h2>
  <p><span class="highlight">Warehouse Name:</span> {order?.warehouseDetails?.warehouseName}</p>
  {/* <p><span class="highlight">Warehouse ID:</span> 12345</p> */}
</div>

<div class="section">
  <h2>Order Details</h2>
  <div class="flex-row">
    <div class="card">
      <p><span class="highlight">Product Name:</span> {order?.orderDetails?.productName}</p>
      <p><span class="highlight">Product Type:</span> {order?.orderDetails?.productType}</p>
      <p><span class="highlight">Quantity:</span> {order?.orderDetails?.quantity}</p>
      <p><span class="highlight">Customer Name:</span> {order?.orderDetails?.customerName}</p>
      <p><span class="highlight">Customer Address:</span> {order?.orderDetails?.customerAddress}</p>
    </div>

    <div class="card">
      <p><span class="highlight">Is Stackable:</span> {order?.orderDetails?.isStackable ? "Yes" : "No"}</p>
      <p><span class="highlight">Is Fragile:</span> {order?.orderDetails?.isFragile ? "Yes" : "No"}</p>
      <p><span class="highlight">Box Dimensions:</span> {order?.boxDimensions?.height}x{order?.boxDimensions?.width} inches</p>
    </div>
  </div>
</div>

<div class="section">
  <h2>Packaging Details</h2>
  <p><span class="highlight">Pick and Pack:</span> {order?.packagingDetails?.pickAndPack ? "Yes" : "No"}</p>
  <p><span class="highlight">Labeling:</span> {order?.packagingDetails?.labeling ? "Yes" : "No"}</p>
</div>

<div class="section">
  <h2>Shipping Details</h2>
  <p><span class="highlight">Carrier:</span> {order?.shippingDetails?.carrier}</p>
  <p><span class="highlight">Tracking Number:</span> {order?.shippingDetails?.trackingNumber}</p>
  <p className="status"><span class="highlight">Status:</span> <span className={order?.shippingDetails?.status === "Approved" ? "approved" : "pending"}>{order?.shippingDetails?.status}</span></p>
</div>

<div class="section">
  <h2>Total Cost</h2>
  <p><span class="highlight">Total Cost:</span> ${order?.totalCost}</p>
</div>
            </div>
            </div>
    )
}