import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAsyncThunk, updateOrderAsyncThunk } from "../../redux/pagesSlices/orderSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import { getWarehouseAsyncThunk, updateWarehouseAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import { categories } from "../../utils/categoriesList";
import { toast } from "react-toastify";

export default function EditOrderModal({modalShow,handleModalHide,id}){
    const dispatch = useDispatch();
    useEffect(()=>{
        if(id){
            dispatch(getOrderAsyncThunk(id))
        }
    },[id,dispatch])
    const order = useSelector((state) => state.order?.order);
    console.log("order",order);
    const warehouseId = order?.warehouseDetails?.warehouseId
    console.log("warehouseId",warehouseId);
    useEffect(()=>{
        if(warehouseId){
            dispatch(getWarehouseAsyncThunk(warehouseId))
        }
    },[dispatch,warehouseId])
    const warehouse = useSelector((state) => state.warehouse?.warehouse);
    console.log("warehouse",warehouse);
    
    
    
    const orderSchema = Yup.object().shape({
        orderDetails : Yup.object().shape({
            productName : Yup.string().required("Product name is required"),
            quantity: Yup.number()
            .required("Quantity is required")
            .min(1, "Quantity must be at least 1")
            .test(
                "max-remaining-capacity",
                "Quantity cannot exceed the remaining capacity",
                function (value) {
                    const total = warehouse?.capacity?.total || 0;
                    const used = warehouse?.capacity?.remaining || 0;
                    const existingQuantity = this.options.context?.existingQuantity || 0; // Get existing quantity
                    const availableCapacity = used + existingQuantity; // Adjust available capacity
                    return value <= availableCapacity;
                }
            ),
            
            isStackable : Yup.string().required("Please select if it's stackable or not"),
            isFragile : Yup.string().required("Please select if it's fragile or not"),
            customerName : Yup.string().required("Your name is required"),
            customerAddress : Yup.string().required("Your address is required"),
            productType : Yup.string().required("Product type is required"),
        }),
        boxDimensions : Yup.object().shape({
            height : Yup.number().required("Height is required").equals([25], "Box height must be 25"),
            width : Yup.number().required("Width is required").equals([18], "Box width must be 18"),
        }),
        packagingDetails : Yup.object().shape({
            pickAndPack : Yup.string().required("Please select if it's pick and pack or not"),
            labeling : Yup.string().required("Please select if it's labeled or not"),
        }),
        totalCost : Yup.number(),
        shippingDetails : Yup.object().shape({
            carrier : Yup.string().required("Carrier name is required"),
            trackingNumber : Yup.string().required("Tracking number is required"),
        })
    })
    const updateOrder = async(values)=>{
        console.log("values",values);
        const quantity = Number(values?.orderDetails?.quantity) || 0; // Ensure quantity is a number
        const usedCapacity = Number(warehouse?.capacity?.remaining) || 0; // Ensure used capacity is a number
        
        const updatedCapacity = usedCapacity - quantity; // Perform the calculation
        const isWarehouseFull = usedCapacity <= 0;
        dispatch(updateOrderAsyncThunk({
            id,
            data : values,
            callback : ()=>{
                toast.success("Order Updated Successfully")
            }
        }))
        dispatch(updateWarehouseAsyncThunk({
            id : warehouse?.id,
            data : {
                capacity : {
                    ...warehouse?.capacity,
                    remaining : updatedCapacity
                },
                isActive : isWarehouseFull ? "false" : "true"
            }
        }))
        // handleModalHide();
    }
    return(
        <Modal
        centered
        size="lg"
        show={modalShow}
        onHide={handleModalHide}
        className="modal-wrapper"
        >   
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <Formik
                initialValues={{
                    warehouseDetails : {
                        warehouseName : order?.warehouseDetails?.warehouseName || "",
                        warehouseId : order?.warehouseDetails?.warehouseId
                    },
                    orderDetails : {
                        productName : order?.orderDetails?.productName || "",
                        quantity : order?.orderDetails?.quantity || "",
                        isStackable : order?.orderDetails?.isStackable || "false",
                        isFragile : order?.orderDetails?.isFragile || "false",
                        customerName : order?.orderDetails?.customerName || "",
                        customerAddress : order?.orderDetails?.customerAddress || "",
                        productType : order?.orderDetails?.productType || "",
                    },
                    boxDimensions : {
                        width : order?.boxDimensions?.width || "",
                        height : order?.boxDimensions?.height || "",
                    },
                    packagingDetails : {
                        pickAndPack : order?.packagingDetails?.pickAndPack || "false",
                        labeling : order?.packagingDetails?.labeling || "false",
                    },
                    totalCost : order?.totalCost || "",
                    shippingDetails : {
                        carrier : order?.shippingDetails?.carrier || "",
                        trackingNumber : order?.shippingDetails?.trackingNumber || "",
                    }
                    
                }}
                enableReinitialize
                context={{ existingQuantity: order?.orderDetails?.quantity || 0 }} 
                validationSchema={orderSchema}
                onSubmit={updateOrder}
                >
                    {({values,handleBlur,handleChange,handleSubmit,errors,touched})=>(
                        <div className="buy-warehouse-form">
                            <div className="form-section">
                                <strong>
                                    Warehouse Details
                                </strong>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Warehouse Name</label>
                                            <input type="text" value={values.warehouseDetails.warehouseName} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Available Capacity</label>
                                            <input type="number" value={warehouse?.capacity?.remaining || 0} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <strong>Order Details</strong>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Product Name</label>
                                            <input type="text" value={values.orderDetails.productName} onChange={handleChange} name="orderDetails.productName" onBlur={handleBlur} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Product Type</label>
                                            <select name="orderDetails.productType" value={values?.orderDetails?.productType} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select Product Type</option>
                                            {categories.map((category,index) => (
                                                <option value={category} key={index}>{category}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Quantity</label>
                                            <input type="number" name="orderDetails.quantity" value={values.orderDetails.quantity} onChange={handleChange} onBlur={handleBlur}/>
                                            {errors?.orderDetails?.quantity && touched?.orderDetails?.quantity && <span className="error">{errors?.orderDetails?.quantity}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="single-field">
                                        <label htmlFor="">Is Stackable?</label>
                                        <select name="orderDetails.isStackable" value={values?.orderDetails?.isStackable} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="single-field">
                                        <label htmlFor="">Is Fragile?</label>
                                        <select name="orderDetails.isFragile" value={values?.orderDetails?.isFragile} onChange={handleChange} onBlur={handleBlur} id="">
                                            <option value="">Select option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="single-field">
                                        <label htmlFor="">Your Name</label>
                                        <input type="text" value={values?.orderDetails?.customerName} onChange={handleChange} onBlur={handleBlur} name="orderDetails.customerName" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Your Address</label>
                                            <input type="text" value={values?.orderDetails?.customerAddress} onChange={handleChange} name="orderDetails.customerAddress" onBlur={handleBlur} />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="form-section">
                                <strong>Packaging Details</strong>
                                <div className="form-section">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-field">
                                                <label htmlFor="">Packing?</label>
                                                <select name="packagingDetails.pickAndPack" value={values?.packagingDetails?.pickAndPack} onChange={handleChange} onBlur={handleBlur} id="">
                                                <option value="">Select option</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                        </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-field">
                                                <label htmlFor="">Labeling?</label>
                                                <select name="packagingDetails.labeling" value={values?.packagingDetails?.labeling}  onChange={handleChange} onBlur={handleBlur} id="">
                                                <option value="">Select option</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <strong>
                                    Box Dimensions (in inches)
                                </strong>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Height </label>
                                            <input type="number" value={values?.boxDimensions?.height} name="boxDimensions.height" onChange={handleChange} onBlur={handleBlur}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Width</label>
                                            <input type="number" value={values?.boxDimensions?.width} name="boxDimensions.width" onChange={handleChange} onBlur={handleBlur} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <strong>Shipping Details</strong>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Carrier Name</label>
                                            <input type="text" value={values?.shippingDetails?.carrier} name="shippingDetails.carrier" onChange={handleChange} onBlur={handleBlur} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-field">
                                            <label htmlFor="">Tracking Number</label>
                                            <input type="text" name="shippingDetails.trackingNumber" value={values?.shippingDetails?.trackingNumber} onChange={handleChange} onBlur={handleBlur}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-field">
                                        <input type="number" name="totalCost" value={values?.totalCost} disabled />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="submit-button" type="submit" onClick={handleSubmit}>
                                    Update Order
                                </button>
                        </div>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}