import { Link, useLocation } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { FaClipboard, FaWarehouse } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BsClipboardCheckFill } from "react-icons/bs";
import { MdPayment } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
// import homeIcon from "../../assests/images/homeIcon.svg"

const sidebarItems = [
    {
        id:1,
        title:"Dashboard",
        icon:<IoMdHome />,
        link:"/"
    },
    {
        id:2,
        title:"Add Warehouse",
        icon:<FaWarehouse />,
        link:"/warehouse"
    },
    {
        id:3,
        title:"My Warehouses",
        icon:<FaWarehouse />,
        link:"/my-warehouses"
    },
    {
        id:4,
        title:"Orders",
        icon:<BsClipboardCheckFill />,
        link:"/orders"
    },
    {
        id:5,
        title:"My Partners/Sellers",
        icon:<TbUsers />,
        link:"/my-partners"
    },
    {
        id:6,
        title:"Payment Details",
        icon:<MdPayment />,
        link:"/payment-details"
    }

]
const sellerSidebarItems = [
    {
        id:1,
        title:"Dashboard",
        icon:<IoMdHome />,
        link:"/"
    },
    {
        id:2,
        title:"View Warehouses",
        icon:<FaWarehouse />,
        link:"/warehouse-listing"
    },
    {
        id:3,
        title:"My Orders",
        icon:<FaClipboard />,
        link:"/my-orders"
    }
]

export default function Sidebar() {
    const location = useLocation()
    const user = useSelector((state) => state.auth.user);
    const userRole = user?.role
    // console.log("userRole",userRole);

    const itemsToMap = userRole === "brand" ? sidebarItems : sellerSidebarItems
    
    return (
        <>
        <div className={`${location.pathname === "/login" || location.pathname === "/signup" ? "d-none" : "sidebar"} `}>
{/* 
<div className="logo-section">
   <a href="/dashboard">
   <img src="" alt="" />
   </a>
</div>
*/}
<ul className="sidebar-nav p-3 overflow-y-auto">
   {itemsToMap.map((item) => (
   <li className="sidebar-nav-item" key={item.id}>
      <Link 
      className={`sidebar-nav-link ${location.pathname === item.link ? "active" : ""}`} 
      to={item.link}
      >
      <span className="icon-image">{item.icon}</span>
      <span className="title">{item.title}</span></Link>
   </li>
   ))}
</ul>
</div>
        {/* <aside class="dashboard-sidebar  ">
            <div className="p-4">
                <a href="/dashboard">

                </a>
                <ul className="sidebar-nav p-3 overflow-y-auto">
                    <li className="sidebar-nav-item">
                        <a className="sidebar-nav-link active" href="/dashboard">
                        <img src={homeIcon} alt="home" />
                        <span>Dashboard</span>
                        </a>
                    </li>
                </ul>
           </div>
        </aside> */}
        </>
    );
}


