import { useEffect, useState } from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { getOrdersAsyncThunk } from "../redux/pagesSlices/orderSlice";

export default function Pagination({paginationData,thunk}){
    // console.log("Order data ",paginationData);
    const [currentPage, setCurrentPage] = useState(paginationData?.page || 1);
    const totalPages = Math.ceil(paginationData?.totalResults / paginationData?.limit);
    const dispatch = useDispatch();
    // Function to handle page changes
    useEffect(() => {
        dispatch(thunk({ page: 1 })); // Fetch page 1 data initially
    }, [dispatch]);

    // Function to handle button click for page changes
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            dispatch(thunk({ page }));  // Fetch or update data for the new page
        }
    };
    
    return(
        <div className="pagination-section">
            {/* Displaying records range */}
            <div className="records-number">
                {`Showing ${
                    Math.min((currentPage - 1) * paginationData?.limit + 1, paginationData?.totalResults)
                } - ${
                    Math.min(currentPage * paginationData?.limit, paginationData?.totalResults)
                } of ${paginationData?.totalResults} results`}
            </div>

            {/* Pagination controls */}
            <div className="controls">
                <div className="control">
                    {/* First Page Button */}
                    <button
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                    >
                        <HiChevronDoubleLeft />
                    </button>

                    {/* Previous Page Button */}
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <HiChevronLeft />
                    </button>

                    {/* Current Page Display */}
                    <button className="count">
                        {currentPage}
                    </button>

                    {/* Next Page Button */}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <HiChevronRight />
                    </button>

                    {/* Last Page Button */}
                    <button
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        <HiChevronDoubleRight />
                    </button>
                </div>
            </div>
        </div>
    )
}