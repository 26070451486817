import { FaEye, FaTrash } from "react-icons/fa";
import PageHeader from "../PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteOrderAsyncThunk, getAllOrdersAsyncThunk, getOrdersAsyncThunk } from "../../redux/pagesSlices/orderSlice";
import OrdersPageHeader from "../OrdersPageHeader";
import { Link } from "react-router-dom";
import Pagination from "../Pagination";

export default function Orders() {
    const [trackingNumber,setTrackingNumber] = useState();
    const [status,setStatus] = useState("")
    // const [customerName,setCustomerName] = useState("")
    const [totalCost,setTotalCost] = useState()
    const [search,setSearch] = useState("")
    const [category,setCategory] = useState("")
    const [date,setDate] = useState("")
    const dispatch = useDispatch();
    const handleDeleteOrder = async(id) => {
        await dispatch(deleteOrderAsyncThunk(id))
    }
    useEffect(()=>{
        let params = {
            populate : 'warehouseId'
        }
        if(trackingNumber){
            params['trackingNumber'] = trackingNumber
        }
        if(category){
            params['category'] = category
        }
        if(totalCost){
            params['totalCost'] = totalCost
        }
        if(status){
            params['status'] = status
        }
        if(search){
            params['search'] = search
        }
        // if(date){
        //     params['date'] = date
        // }
        dispatch(getAllOrdersAsyncThunk(params))
    },[dispatch,category,totalCost,trackingNumber,status,search])
    const orders = useSelector((state) => state.order?.orders?.results);
    // console.log("orders",orders);
    // console.log("Date",date);
    const paginationData = useSelector((state)=>state?.order?.orders)
    
    return(
        <div className="page-wrapper">
            <div className="container">
            <div className="page-title">
            <h1>Orders</h1>
            </div>
            <OrdersPageHeader date={date} setDate={setDate} search={search} setSearch={setSearch} trackingNumber={trackingNumber} setTrackingNumber={setTrackingNumber} status={status} setStatus={setStatus} category={category} setCategory={setCategory} totalCost={totalCost} setTotalCost={setTotalCost}/>
            <div className="warehouse-listing-table">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Tracking Number</th>
                            <th>Customer Name</th>
                            <th>Customer Address</th>
                            <th>Order Date</th>
                            <th>Order Status</th>
                            <th>Order Total</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.map((order,index)=>(
                            <tr key={order.id}>
                            
                            <td>{index+1}</td>
                            <td>{order?.shippingDetails?.trackingNumber}</td>
                            <td>{order?.orderDetails?.customerName}</td>
                            <td>{order?.orderDetails?.customerAddress}</td>
                            <td>{order?.createdAt?.slice(0,10)}</td>
                            <td><span className="completed">{order?.shippingDetails?.status}</span></td>
                            <td>${order?.totalCost}</td>
                            <td className="action-buttons">
                                <Link to={`/order-detail/${order.id}`}>
                                <FaEye />
                                </Link>
                                <button onClick={()=>handleDeleteOrder(order.id)}>
                                    <FaTrash />
                                </button>
                                </td>
                        </tr>
                        ))}
                        
                    </tbody>
                </table>
            </div>
            <Pagination paginationData={paginationData} thunk={getAllOrdersAsyncThunk}/>
            </div>
            
        </div>
    )
}