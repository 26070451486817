import { createAsyncThunk,isAnyOf,createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import { catchAsync, handleLoadingErrorParamsForAsycThunk, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { authenticateAsyncThunk } from "./authSlice";
import { toast } from "react-toastify";

export const getOrdersAsyncThunk = createAsyncThunk(
    "order/getOrdersAsyncThunk",
    catchAsync(async (params, _) => {
        const response = await ApiRequests.getOrders(params);
        return response?.data;
    })

)

export const getOrderAsyncThunk = createAsyncThunk(
    "order/getOrderAsyncThunk",
    catchAsync(async (id, _) => {
        const response = await ApiRequests.getOrder(id);
        return response?.data;
    })
)
export const getAllOrdersAsyncThunk = createAsyncThunk(
    "order/getAllOrdersAsyncThunk",
    catchAsync(async (params, _) => {
        const response = await ApiRequests.getAllOrders(params);
        return response?.data;
    })
)
export const createOrderAsyncThunk = createAsyncThunk(
    "order/createOrderAsyncThunk",
    catchAsync(async ({ data, callback }, { dispatch, getState }) => {
        const response = await ApiRequests.createOrder(data);
        if (response.status === 201) {
            toast.success("Order placed successfully")
        }
        if (callback) callback();
        // let params = {}
        // let state1 = getState().listings
        // if (state1.search) params.name = state1.search;
        // if (state1.order) params.sortBy = `name:${state1.order}`;
        // dispatch(getOrdersAsyncThunk({ ...params, populate: "provider_id" }))
        return response?.data;
    })
)

export const updateOrderAsyncThunk = createAsyncThunk(
    "order/updateOrderAsyncThunk",
    async ({ id, data, callback }, { dispatch, getState }) => {
        try {
            const response = await ApiRequests.updateOrder(id, data);
            // if (response.status === 200) {
            //     toast.success("Order updated successfully");
            // }
            if (callback) callback();
            let state1 = getState().listings;
            dispatch(authenticateAsyncThunk());
            return response?.data;
        } catch (error) {
            toast.error("Failed to update order");
            console.error("Update Error:", error);
            throw error;
        }
    }
);

export const deleteOrderAsyncThunk = createAsyncThunk(
    "order/deleteOrderAsyncThunk",
    catchAsync(async (id, { dispatch, getState }) => {
        const response = await ApiRequests.deleteOrder(id);
        if (response.status === 204) {
            toast.success("Order deleted successfully")
            let params = {}
            // let state = getState().listings;
            // if (state.search) params.name = state.search;
            // if (state.order) params.sortBy = `name:${state.order}`;
            dispatch(getOrdersAsyncThunk({ ...params }))
        }
        else {
            toast.error(response.error)
        }
        return id;
    })
)

const initialState = {
    orders: {
        page: 1,
        orders: [],
        totalPages: 1,
    },
    listings : {
        page: 1,
        results: [],
        totalPages: 1,
    },
    order : null,
    errors : {},
    loadings : {},
    errorMessages : {},
    errorCodes : {},
    paramsForThunk : {},
    search : null,
    categoryId : null,
    categories : [],
    // order : "asce",
}

const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setSearchValue(state, action) {
            state.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
                .addCase(updateOrderAsyncThunk.fulfilled, (state, action) => {
                    state.order = action.payload;
                })
                .addCase(getOrdersAsyncThunk.pending, (state, action) => {
                    if(action.meta?.arg?.page > 1 || !action.meta?.arg?.page) {
                        state.orders = {
                            page : 1,
                            results : [],
                            totalPages : 1,
                        }
                    }
                })
                .addCase(getOrdersAsyncThunk.fulfilled, (state, action) => {
                    if(action.payload?.page > 1) {
                        state.orders = {
                            ...action.payload,
                            orders : state?.orders?.results.concat(action?.payload?.results),
                        }
                    }
                    else {
                        state.orders = action.payload;
                    }
                })
                .addCase(getAllOrdersAsyncThunk.pending, (state, action) => {
                    if(action.meta?.arg?.page > 1 || !action.meta?.arg?.page) {
                        state.orders = {
                            page : 1,
                            results : [],
                            totalPages : 1,
                        }
                    }
                })
                .addCase(getAllOrdersAsyncThunk.fulfilled, (state, action) => {
                    if(action.payload?.page > 1) {
                        state.orders = {
                            ...action.payload,
                            orders : state?.orders?.results.concat(action?.payload?.results),
                        }
                    }
                    else {
                        state.orders = action.payload;
                    }
                })
                .addCase(getOrderAsyncThunk.fulfilled,(state,action)=>{
                    state.order = action.payload
                })
                .addCase(deleteOrderAsyncThunk.fulfilled,(state,action)=>{
                    const deleteId = action.payload
                    if(state.orders?.results){
                        state.orders = {
                            ...state.orders,
                            totalResults : state.orders.totalResults - 1,
                            results : state.orders.results.filter((order) => order.id !== deleteId)
                        }
                    }
                    if(state.ordersCount?.results){
                        state.ordersCount = {
                            ...state.ordersCount,
                            totalResults : state.ordersCount.totalResults - 1,
                            results : state.ordersCount.results.filter((order) => order.id !== deleteId)
                        }
                    }
                })
                .addMatcher(
                    isAnyOf(
                        ...reduxToolKitCaseBuilder([
                            createOrderAsyncThunk,
                            updateOrderAsyncThunk,
                            deleteOrderAsyncThunk,
                            getOrdersAsyncThunk,
                            getOrderAsyncThunk,
                            getAllOrdersAsyncThunk
                        ])
                    ),
                    handleLoadingErrorParamsForAsycThunk
                )
}
})

export default orderSlice.reducer
export const { setSearchValue,setLoading } = orderSlice.actions