import { FaEye, FaTrash } from "react-icons/fa";
import PageHeader from "../PageHeader";

export default function MyPartners() {
    return(
        <div className="page-wrapper">
            <div className="page-title">
                <h1>My Partners</h1>
            </div>
            <PageHeader />
            <div className="warehouse-listing-table">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Partner Name</th>
                            <th>Address</th>
                            <th>Contact Number</th>
                            <th>Contract Starting Date</th>
                            <th>Status</th>
                            {/* <th>Order Total</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>John Doe</td>
                            <td>Texas, United States</td>
                            <td>+(1) 234 5678 90</td>
                            <td>2022-01-01</td>
                            <td><span className="completed">Active</span></td>
                            {/* <td>$100.00</td> */}
                            <td className="action-buttons"><button>
                                <FaEye />
                                </button>
                                <button>
                                    <FaTrash />
                                </button>
                                </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}