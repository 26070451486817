import { Formik } from "formik"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { createWarehouseAsyncThunk } from "../redux/pagesSlices/warehouseSlice";
import { countries } from 'countries-list';
import Sidebar from "./common/Sidebar";
import { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { categories } from "../utils/categoriesList";

const countryArray = Object.values(countries);

export default function WareHouseForm() {
    const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [isFetching, setIsFetching] = useState(false);

  const categoryOptions = categories.map((category) => ({
    label: category,
    value: category.toLowerCase().replace(/\s+/g, "-"),
  }));

  const getLocation = (setFieldValue) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setFieldValue("location.latitude", position.coords.latitude);
        setFieldValue("location.longitude", position.coords.longitude);
        toast.success("Location fetched successfully");
      },
      (error) => {
        console.error("Error fetching location:", error.message);
      }
    );
  };
    

    const wareHouseValidationSchema = Yup.object().shape({
        name: Yup.string(),
        location: Yup.object().shape({
            street: Yup.string(),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zipCode: Yup.string(),
            country: Yup.string().required("Country is required"),
        }),
        categories: Yup.array()
  .of(Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }))
  .min(1, "At least one category is required")
  .required("Categories are required"),
        capacity: Yup.object().shape({
            total: Yup.number()
        .required("Total capacity is required")
        .min(1, "Total capacity must be at least 1"),
    remaining: Yup.number()
        .required("Remaining capacity is required")
        .test(
            "is-less-than-total",
            "Remaining capacity must be less than total capacity",
            function (value) {
                return value <= this.parent.total; // Compare remaining with total
            }    ),
    }),
        size : Yup.number().required("Mention your warehouse size"),
        height : Yup.number().required("Mention your warehouse height"),
        pricing: Yup.object().shape({
            storagePerUnit: Yup.number().required("Storage per unit is required"),
            fulfillmentFee: Yup.number().required("Fulfillment fee is required"),
        }),
        services: Yup.object().shape({
            pickAndPack: Yup.string(),
            labeling: Yup.string(),
            expeditedShipping: Yup.string(),
        })        
    })
    const dispatch = useDispatch();
    const handleAddWarehouse = async (values, formControls) => {
        console.log("Values", values);
        const formattedCategories = values?.categories?.map(category => category?.label);

        console.log("Formatted categories", formattedCategories);
        
        const formData = new FormData();
        formData.append("name", values?.name);
        formData.append("location", JSON.stringify(values.location));
        formData.append("capacity", JSON.stringify(values.capacity));
        formData.append("pricing", JSON.stringify(values.pricing));
        formData.append("services", JSON.stringify(values.services));
        formData.append("size",values?.size)
        formData.append("description", values?.description);
        formData.append("height", values?.height);
        formattedCategories.forEach((category, index) => {
            formData.append(`categories[${index}]`, category);
        })
        // formData.append("categories",JSON.stringify(formattedCategories))
        // Add images
        if (values.images && values.images.length) {
            values.images.forEach((image) => {
                formData.append("images", image);
            });
        }
        // formControls.resetForm();
        
        await dispatch(createWarehouseAsyncThunk({
            data: formData,
        }))
        formControls.resetForm();
    }
    return (
        <div className="main-page-wrapper">
        
        <div className="warehouse-form-wrapper">
            <Formik
                initialValues={{
                    name: "",
                    images : [],
                    location: {
                        street: "",
                        city: "",
                        state: "",
                        zipCode: "",
                        country: "",
                        latitude: 0,
                        longitude: 0
                    },
                    categories : [],
                    description : "",
                    capacity: {
                        total: 0,
                        remaining: 0,
                    },
                    height:"",
                    size : "",
                    pricing: {
                        storagePerUnit: 0,
                        fulfillmentFee: 0,
                    },
                    services: {
                        pickAndPack: "",
                        labeling: "",
                        expeditedShipping: "",
                    },

                }}
                onSubmit={handleAddWarehouse}
                validationSchema={wareHouseValidationSchema}
            >
                {({ values, handleBlur, handleChange, handleSubmit,errors,touched,setFieldValue }) => (
                    <div className="form-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="single-field flex flex-col gap-2">
                            <label htmlFor="">Enter Warehouse Name</label>
                            <input type="text" placeholder="Enter Warehouse Name" value={values.name} name="name" onBlur={handleBlur} onChange={handleChange} />
                            {errors?.name && touched?.name && <span className="text-danger">{errors?.name}</span>}
                        </div>
                            </div>
                            <div className="col-md-6">
                            <div className="single-field flex flex-col gap-2">
                            <label htmlFor="">Upload Warehouse Images</label>
                            <input type="file" multiple name="images" onBlur={handleBlur} onChange={(event) => {
                            const files = Array.from(event.target.files);
                            setFieldValue("images", files); // Update the images field in Formik state
                            }} />
                            {errors?.name && touched?.name && <span className="text-danger">{errors?.name}</span>}
                        </div>
                        
                            </div>
                            <div className="single-field flex flex-col gap-2">
                            <label htmlFor="">Describe your warehouse</label>
                            <textarea rows={3} type="text" placeholder="Describe your warehouse" value={values.description} name="description" onBlur={handleBlur} onChange={handleChange} />
                            {errors?.description && touched?.description && <span className="text-danger">{errors?.description}</span>}
                        </div>
                        </div>
                        <div className="form-section">
                  <h2>Categories *</h2>
                  <div className="single-field">
                    <label>Select Categories</label>
                    <Select
                      isMulti
                      name="categories"
                      options={categoryOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={values.categories}
                      onChange={(selected) => setFieldValue("categories", selected)}
                      onBlur={handleBlur}
                    />
                    {errors.categories && touched.categories && (
                      <span className="text-danger">{errors.categories}</span>
                    )}
                  </div>
                </div>
                        
                        <div className="form-section">
                            <h2>Address *</h2>
                            <div className="row">
                                <div className="single-field col-md-4 mb-3">
                                    <label htmlFor="">Street</label>
                                    <input type="text" placeholder="Street Address" value={values.location.street} name="location.street" onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">State</label>
                                    <input type="text" placeholder="State" value={values.location.state} name="location.state" onChange={handleChange} onBlur={handleBlur} />
                                    {errors.location?.state && touched.location?.state && <span className="text-danger">{errors.location?.state}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Zip Code</label>
                                    <input type="number" placeholder="Zip Code" value={values.location.zipCode} name="location.zipCode" onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Country</label>
                                    <select name="location.country" value={values.location.country} onChange={handleChange} onBlur={handleBlur} id="">
                                        <option value="">Select Country</option>
                                        {countryArray.map((country) => (
                                            <option value={country.name}>{country.name}</option>
                                        ))}
                                    </select>
                                    {errors.location?.country && touched.location?.country && <span className="text-danger">{errors.location?.country}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">City</label>
                                    <input type="text" placeholder="City" value={values.location.city} name="location.city" onChange={handleChange} onBlur={handleBlur} />
                                    {errors.location?.city && touched.location?.city && <span className="text-danger">{errors.location?.city}</span>}
                                </div>
                                <div className="single-field location-btn col-md-4">
                                <button onClick={() => getLocation(setFieldValue)} disabled={isFetching}>
        {isFetching ? "Fetching Location..." : "Get Current Location"}
      </button>
      {/* <label>
          Latitude:
          <input
            type="text"
            value={values.location.latitude}
            readOnly
            placeholder="Latitude"
          />
        </label>
        <br />
        <label>
          Longitude:
          <input
            type="text"
            value={values.location.longitude}
            readOnly
            placeholder="Longitude"
          />
        </label> */}
                                </div>
                            </div>

                        </div>
                        <div className="form-section">
                            <h2>Capacity *</h2>
                            <div className="row">
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Total Quantity *</label>
                                    <input type="number" name="capacity.total" value={values.capacity.total} onChange={handleChange} onBlur={handleBlur} placeholder="Storage Per Unit" />
                                        {errors.capacity?.total && touched.capacity?.total && <span className="text-danger">{errors.capacity?.total}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Remaining Quantity *</label>
                                    <input type="number" name="capacity.remaining" value={values.capacity.remaining} onChange={handleChange} onBlur={handleBlur} placeholder="Fullfillment fee" />
                                        {errors.capacity?.remaining && touched.capacity?.remaining && <span className="text-danger">{errors.capacity?.remaining}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Warehouse Size (Square Feets) *</label>
                                    <input type="number" name="size" value={values.size} onChange={handleChange} onBlur={handleBlur} placeholder="Warehouse size" />
                                        {errors?.size && touched?.size && <span className="text-danger">{errors?.size}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Warehouse Height (Meters) *</label>
                                    <input type="number" name="height" value={values.height} onChange={handleChange} onBlur={handleBlur} placeholder="Warehouse height" />
                                        {errors?.height && touched?.height && <span className="text-danger">{errors?.height}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2>Pricing *</h2>
                            <div className="row">
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Storage Per Unit *</label>
                                    <input type="number" name="pricing.storagePerUnit" value={values.pricing.storagePerUnit} onChange={handleChange} onBlur={handleBlur} placeholder="Storage Per Unit" />
                                        {errors.pricing?.storagePerUnit && touched.pricing?.storagePerUnit && <span className="text-danger">{errors.pricing?.storagePerUnit}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Fullfillment fee *</label>
                                    <input type="number" name="pricing.fulfillmentFee" value={values.pricing.fulfillmentFee} onChange={handleChange} onBlur={handleBlur} placeholder="Fullfillment fee" />
                                        {errors.pricing?.fulfillmentFee && touched.pricing?.fulfillmentFee && <span className="text-danger">{errors.pricing?.fulfillmentFee}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2>Services *</h2>
                            <div className="row">
                                <div className="single-field col-md-4 mb-3">
                                    <label htmlFor="">PickPack? *</label>
                                    <select name="services.pickAndPack" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.pickAndPack}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.pickAndPack && touched.services?.pickAndPack && <span className="text-danger">{errors.services?.pickAndPack}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Labeling? *</label>
                                    <select name="services.labeling" onChange={handleChange} onBlur={handleBlur} id="" value={values.services?.labeling}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.labeling && touched.services?.labeling && <span className="text-danger">{errors.services?.labeling}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Expedited Shipping? *</label>
                                    <select name="services.expeditedShipping" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.expeditedShipping}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.expeditedShipping && touched.services?.expeditedShipping && <span className="text-danger">{errors.services?.expeditedShipping}</span>}
                                </div>
                            </div>
                        </div>

                        <button className="submit-button" type="submit" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                )}
            </Formik>
        </div>
        </div>
        
    )
}