import { useEffect, useState } from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrdersAsyncThunk } from "../../redux/pagesSlices/orderSlice";
import EditOrderModal from "../modals/EditOrderModal";
import Pagination from "../Pagination";
import PageHeader from "../PageHeader";
import OrdersPageHeader from "../OrdersPageHeader";

export default function MyOrdersList(){
    const dispatch = useDispatch();
    const [trackingNumber,setTrackingNumber] = useState();
    const [status,setStatus] = useState("")
    // const [customerName,setCustomerName] = useState("")
    const [totalCost,setTotalCost] = useState()
    const [search,setSearch] = useState("")
    const [category,setCategory] = useState("")
    const [date,setDate] = useState("")
    const user = useSelector((state)=>state?.auth?.user)
    useEffect(()=>{
        let params = {
            populate:'brandId'
        }
        if(trackingNumber){
            params['trackingNumber'] = trackingNumber
        }
        if(category){
            params['category'] = category
        }
        if(totalCost){
            params['totalCost'] = totalCost
        }
        if(status){
            params['status'] = status
        }
        if(search){
            params['search'] = search
        }
        dispatch(getOrdersAsyncThunk(params))
    },[dispatch,category,totalCost,trackingNumber,status,search])
    const paginationData = useSelector((state)=>state?.order?.orders)
    const orders = useSelector((state)=>state.order?.orders?.results)
    const [selectedOrderId,setSelectedOrderId] = useState(null)
    const [modalShow,setModalShow] = useState()
    const handleModalShow = (id)=>{
        if(selectedOrderId === id) return;
        setSelectedOrderId(id)
        setModalShow(true)
    } 
    const handleModalHide = () => {
    setSelectedOrderId(null);
    setModalShow(false);
    }
   

    return(
        <div className="page-wrapper">
            <div className="container">
                <div className="page-title">
                    <h1>My Orders</h1>
                </div>
                <OrdersPageHeader date={date} setDate={setDate} search={search} setSearch={setSearch} trackingNumber={trackingNumber} setTrackingNumber={setTrackingNumber} status={status} setStatus={setStatus} category={category} setCategory={setCategory} totalCost={totalCost} setTotalCost={setTotalCost}/>

                <div className="warehouse-listing-table">
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Tracking Number</th>
                                <th>Warehouse Name</th>
                                {/* <th>Location</th> */}
                                <th>Product Name</th>
                                <th>Product Type</th>
                                <th>Order Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.map((item,index)=>(
                                <tr key={item?.id}>
                                <td>{index + 1}</td>
                                <td>{item?.shippingDetails?.trackingNumber}</td>
                                <td>{item?.warehouseDetails?.warehouseName}</td>
                                {/* <td>United States</td> */}
                                <td>{item?.orderDetails?.productName}</td>
                                <td>{item?.orderDetails?.productType}</td>
                                <td>2024-01-01</td>
                                <td><span className={`${item?.shippingDetails?.status === "Pending" ? "pending" : item?.shippingDetails?.status === "Approved" ? "completed" : "completed"}`}>{item?.shippingDetails?.status}</span></td>
                                <td className="action-buttons">
                                    <Link to={`/order-detail/${item.id}`}>
                                        <FaEye />
                                    </Link>
                                    <button onClick={()=>handleModalShow(item.id)}>
                                        <FaEdit />
                                    </button>
                                    <button>
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                            ))}
                                    <EditOrderModal modalShow={modalShow} handleModalHide={handleModalHide} id={selectedOrderId}/>
                            
                        </tbody>
                    </table>
                </div>
                <Pagination paginationData={paginationData} thunk={getOrdersAsyncThunk}/>
            </div>
        </div>
    )
}