import { APIurls } from "./constants.js";
import apiService from ".";
import multipart from "./multipart.js";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  register: async (data) => await apiService.post(APIurls.register, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshTokens: async (data) => await apiService.post(APIurls.refreshTokens, data),
  revokeToken: async (data) => await apiService.post(APIurls.revokeToken, data),
  
  // warehouse routes
  createWarehouse : async (data) => await multipart.post(APIurls.warehouse, data),
  getWarehouses : async (params) => await apiService.get(APIurls.warehouse, {params}),
  getWarehouse : async (id) => await apiService.get(APIurls.warehouse + `/${id}`),
  getAllWarehouses : async (params) =>  await apiService.get(APIurls.warehouse + "/all", {params}),
  updateWarehouse : async (id,data) => await apiService.patch(APIurls.warehouse + `/${id}`, data),
  deleteWarehouse : async (id) => await apiService.delete(APIurls.warehouse + `/${id}` ),

  // order routes
  createOrder : async (data) => await apiService.post(APIurls.order , data),
  getOrders : async (params) => await apiService.get(APIurls.order, {params}),
  getAllOrders : async (params) =>  await apiService.get(APIurls.order + "/all", {params}),
  getOrder : async (id) => await apiService.get(APIurls.order + `/${id}`),
  updateOrder : async (id,data) => await apiService.patch(APIurls.order + `/${id}`, data),
  deleteOrder : async (id) => await apiService.delete(APIurls.order + `/${id}` ),
};


