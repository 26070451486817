import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllWarehousesAsyncThunk, getWarehousesAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import { getAllOrdersAsyncThunk, getOrdersAsyncThunk } from "../../redux/pagesSlices/orderSlice";

export default function Homepage(){
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    // console.log("user",user);
    useEffect(() => {
        user?.role === "brand" ? dispatch(getWarehousesAsyncThunk({})) : dispatch(getAllWarehousesAsyncThunk({}))
        dispatch(user?.role === "brand" ? getAllOrdersAsyncThunk({}) : getOrdersAsyncThunk({}))
        // dispatch(getAllWarehousesAsyncThunk({}))
        // dispatch(getWarehousesAsyncThunk({}))
        // user?.role === "brand" ? dispatch(getWarehousesAsyncThunk({})) : dispatch(getAllWarehousesAsyncThunk({}))
        dispatch(user?.role === "brand" ? getWarehousesAsyncThunk({}) : getAllWarehousesAsyncThunk())
        // dispatch(getAllOrdersAsyncThunk({}))
        // dispatch(getOrdersAsyncThunk({}))
    },[dispatch])
    const warehouse = useSelector((state) => state.warehouse.warehouses);
    const warehouseLength = warehouse?.totalResults;    
    const order = useSelector((state) => state.order.orders);
    const orderLength = order?.totalResults;
    console.log("warehouse ",warehouse);
    
    return(
        <div className="home-page-wrapper">
            <div className="container">
                {/* <ul>
                    <li>
                        <Link to={"/warehouse"}>Add Warehouse</Link>
                    </li>
                    <li>
                        <Link to={"/warehouse-listing"}>Warehouse Listing</Link>
                    </li>
                </ul> */}
                <h2 className="text-center">
                    Welcome back to your Dashboard
                </h2>
                <div className="home-page-cards">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="single-card">
                                <Link to={"/warehouse-listing"}>
                                    <span>{warehouseLength}</span>
                                    <strong>
                                    Total Warehouses

                                    </strong>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="single-card">
                                <Link to={user?.role === "brand" ? "/orders" : "/my-orders"}>
                                    <span>{orderLength}</span>
                                    <strong>
                                    Total Orders

                                    </strong>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="single-card">
                                <Link to={"/my-partners"}>
                                    <span>{warehouseLength}</span>
                                    <strong>
                                    Total Partners

                                    </strong>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}