import { Formik } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { userRegisterAsyncThunk } from "../redux/pagesSlices/authSlice";
import wareHouseLogo from "../assets/images/warehouse-logo.png"

export default function SignInSide() {

  const dispatch = useDispatch();
  const router = useNavigate();
  const handleUserSignUp = async(values)=>{
    await dispatch(userRegisterAsyncThunk({
      name : values?.name,
      email : values?.email,
      password : values?.password,
      role : values?.role,
      address : values?.address,
      router
    }))
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().min(8).required("Password is required"),
    role: Yup.string().required("Role is required"),
  })
  return (
<div className="container">
  <div className="row">
    <div className="col-md-6 offset-md-3">
      <h2 className="text-center text-dark mt-5">Register</h2>
      <div className="card my-5">
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            role: "",
            address: ""
          }}
          validationSchema={validationSchema}
          onSubmit={handleUserSignUp}
        >
          {({values,handleChange,handleBlur,handleSubmit,errors,touched})=>(
            <form className="card-body cardbody-color p-lg-5">
          <div className="text-center">
            <img
              src={wareHouseLogo}
              className="img-fluid profile-image-pic img-thumbnail my-3"
              width="200px"
              alt="profile"
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
              className="form-control"
              id="Username"
              aria-describedby="emailHelp"
              placeholder="Your Name"
            />
            {touched && errors.name && <span className="text-danger">{errors.name}</span>}
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
              aria-describedby="emailHelp"
              placeholder="abc@example.com"
            />
            {touched && errors.email && <span className="text-danger">{errors.email}</span>}
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              name="password"
              value={values.password}
              onChange={handleChange("password")}
              onBlur={handleBlur("password")}
              id="password"
              placeholder="Password"
            />
            {touched && errors.password && <span className="text-danger">{errors.password}</span>}
          </div>
          <div className="mb-3">
            <textarea
              type="text"
              className="form-control"
              id="address"
              name="address"
              value={values.address}
              onChange={handleChange("address")}
              onBlur={handleBlur("address")}
              aria-describedby="emailHelp"
              placeholder="Address"
              rows={3}
            />
          </div>
          <div className="mb-3">
            <select className="form-control" name="role" value={values.role} onChange={handleChange("role")} onBlur={handleBlur("role")}>
              <option value="">Select your role</option>
              <option value="brand">Brand</option>
              <option value="seller">Seller</option>
            </select>
            {touched && errors.role && <span className="text-danger">{errors.role}</span>}
          </div>
          <div className="text-center">
            <Button type="submit" onClick={handleSubmit} className="px-5 mb-5 w-100">
              Register
            </Button>
          </div>
          <div id="emailHelp" className="form-text text-center mb-5 text-dark">
            Already have account?{" "}
            <Link to="/login" className="text-dark fw-bold">
              Login
            </Link>
          </div>
        </form>
          )}
        
        </Formik>
       
      </div>
    </div>
  </div>
</div>
  );
}
