import { FaSearch } from "react-icons/fa"

export default function PageHeader({location,setLocation,pricing,setPricing,capacity,setCapacity,status,setStatus,search,setSearch}) {
    
    const filterLabels = [
        "Oldest first",
        "Newest first",
        "A-Z",
        "Z-A"
    ]
    return(
        <div className="page-header">
            <div className="filter-dropdown">
                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Filter by location" />
                <input type="number" value={pricing} onChange={(e) => setPricing(e.target.value)} placeholder="Filter by pricing"/>
                <input type="number" value={capacity} onChange={(e) => setCapacity(e.target.value)} placeholder="Filter by capacity"/>
                {/* <select name="" id="">
                    <option value="">Filter</option>
                    {filterLabels.map((label,index) => (
                        <option value={index}>{label}</option>
                    ))}
                </select> */}
                <select name="" id="" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Filter by status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </select>
            </div>
            <div className="search-bar">
                <FaSearch />
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..."/>
            </div>
        </div>
    )
}